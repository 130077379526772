import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { AuthGuard } from 'quards/auth.quard'

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full', canActivate: [AuthGuard], data:{showHeader: true, showFooter: true, showSidebar: true}},
  {path: 'home', loadChildren: ()=> import('modules/admin/admin.module').then(m=>m.ComponentsModule),
     data:{showHeader: true, showFooter: true, showSidebar: true}},
  {path: 'free-trial', loadChildren: ()=> import('modules/trial-demo-simple/trial-demo-simple.module').then(m=>m.TrialDemoSimpleModule),
     data:{showHeader: false, showFooter: false, showSidebar: false}},
  {path: 'free-trial/:language', loadChildren: ()=> import('modules/trial-demo-simple/trial-demo-simple.module').then(m=>m.TrialDemoSimpleModule),
     data:{showHeader: false, showFooter: false, showSidebar: false}},
  {path: 'free-trial/:language/:phrase_enabled', loadChildren: ()=> import('modules/trial-demo-simple/trial-demo-simple.module').then(m=>m.TrialDemoSimpleModule),
     data:{showHeader: false, showFooter: false, showSidebar: false}},
  {path: 'm-free-trial', loadChildren: ()=> import('modules/trial-demo-simple-mobile/trial-demo-simple-mobile.module').then(m=>m.TrialDemoSimpleMobileModule),
     data:{showHeader: false, showFooter: false, showSidebar: false}},
  {path: 'm-free-trial/:language', loadChildren: ()=> import('modules/trial-demo-simple-mobile/trial-demo-simple-mobile.module').then(m=>m.TrialDemoSimpleMobileModule),
     data:{showHeader: false, showFooter: false, showSidebar: false}},
  {path: 'free-trial-mobile', loadChildren: ()=> import('modules/trial-demo/trial-demo.module').then(m=>m.TrialRegistrationModule),
     data: { showHeader: false, showFooter: false, showSidebar: false } }, // free-trial-mobile: only use for FSA app
  {path: 'free-trial-mobile/:language', loadChildren: ()=> import('modules/trial-demo/trial-demo.module').then(m=>m.TrialRegistrationModule),
     data: { showHeader: false, showFooter: false, showSidebar: false } },
  {path: 'powerBiDashboard/:id/:language', loadChildren: ()=> import('modules/power-bi-dashboard/power-bi-dashboard.module').then(m=>m.PowerBiDashboardModule), 
     data:{showHeader: false, showFooter: false, showSidebar: false}},
  {path: 'subscription/:id/:language', loadChildren: ()=> import('modules/stripe/stripe.module').then(m=>m.StripeModule),
     data:{showHeader: false, showFooter: false, showSidebar: false}},
  {path: 'subscription/:id/:language/:obs_user_id', loadChildren: ()=> import('modules/stripe/stripe.module').then(m=>m.StripeModule),
     data:{showHeader: false, showFooter: false, showSidebar: false}},
  {path: 'subscription/:id/:language/:obs_user_id/:phrase_enabled', loadChildren: ()=> import('modules/stripe/stripe.module').then(m=>m.StripeModule),
     data:{showHeader: false, showFooter: false, showSidebar: false}},
   {path: 'registration', loadChildren: ()=> import('modules/stripe/stripe.module').then(m=>m.StripeModule),
     data:{showHeader: false, showFooter: false, showSidebar: false}},  
  {path: '**', redirectTo: 'login' ,data:{showHeader: true, showFooter: true, showSidebar: true}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
