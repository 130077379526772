// import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injectable } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from '../modules/components/components.module';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient  } from '@angular/common/http';
import { AppComponent } from './app.component';
import { JwtInterceptor, ErrorInterceptor } from '../helpers';
import { JwtModule } from '@auth0/angular-jwt';
import { FooterComponent } from 'modules/admin/footer/footer.component';

import { TranslateModule, TranslateLoader, TranslateCompiler, TranslateService } from '@ngx-translate/core';
import { PhraseAppCompiler } from 'ngx-translate-phraseapp'
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserModule } from '@angular/platform-browser';
import { Observable } from 'rxjs'; 
import { TippyModule } from 'ng-tippy';
import { DatePipe, DecimalPipe, CurrencyPipe } from '@angular/common'

import { RecaptchaModule } from 'ng-recaptcha';

import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/app/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    ComponentsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jWtTokenGet
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],

      },
      useDefaultLang: true, defaultLanguage: 'en'

    }),
    BrowserModule,
    HttpClientModule,
    TippyModule,
    RecaptchaModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    DatePipe, DecimalPipe, CurrencyPipe
],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
  currentLang = '';
  constructor(private route: ActivatedRoute, public translate: TranslateService) {
    translate.setDefaultLang('en');
  }
}


export function jWtTokenGet() {
  return localStorage.getItem('jWtToken');
}