import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

export function createTranslateLoaderComponent(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/trial/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoaderComponent),
        deps: [HttpClient]
      },
      extend: true,
      isolate: false,
    })
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent
  ],
  exports: [
    HeaderComponent, SidebarComponent
  ]
})
export class ComponentsModule {
  currentLang = '';
  constructor(private route: ActivatedRoute, public translate: TranslateService) {
    this.currentLang = this.route.snapshot.paramMap.get("language") || 'en';
    translate.setDefaultLang('en');
    translate.use(this.currentLang);
  }
}
