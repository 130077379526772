import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PowerBiReport {

  constructor(private http: HttpClient) { }

  listAllworkspaces(){
    return this.http.get<any>(`${environment.apiUrl}/powerbi/listallworkspaces`).pipe(map(x => x.Result));
  }

  getCustomReportByImplementId(impId){
    return this.http.get<any>(`${environment.apiUrl}/powerbi/get-customreport-by-impid/${impId}`).pipe(map(x => x.Result));
  }

  refreshCustomReport(wsId, reportName, languageCode){
    return this.http.get<any>(`${environment.apiUrl}/powerbi/refresh-customreport/${wsId}/${reportName}/${languageCode}`).pipe(map(x => x.Result));
  }

  getWorkspaceIdByImplementId(impId){
    return this.http.get<any>(`${environment.apiUrl}/powerbi/get-workspaceid-by-impid/${impId}`).pipe(map(x => x.Result));
  }

  getEmbedReportByName(impId, workspaceId, reportName, languageCode){
    return this.http.get<any>(`${environment.apiUrl}/powerbi/get-embedreport-by-name/${impId}/${workspaceId}/${reportName}/${languageCode}`).pipe(map(x => x.Result));
  }

  publishReportOnImp(impId, report_id, reportName){
    return this.http.get<any>(`${environment.apiUrl}/powerbi/process-publish-report-imp/${impId}/${report_id}/${reportName}`).pipe(map(x => x.Result));
  }

  listallReportsOfWorkspace(workspace_id){
    return this.http.get<any>(`${environment.apiUrl}/powerbi/listallreportsofworkspace/${workspace_id}`).pipe(map(x => x.Result));
  }

  listallDashboardOfWorkspace(workspace_id){
    return this.http.get<any>(`${environment.apiUrl}/powerbi/listalldashboardsofworkspace/${workspace_id}`).pipe(map(x => x.Result));
  }

  getEmbedParams(workspace_id, report_id){
    return this.http.get<any>(`${environment.apiUrl}/powerbi/getembeddedinfo/${workspace_id}/${report_id}`).pipe(map(x => x.Result));
  }

  createWorkspaceIdForImp(id) {
    return this.http
      .post<any>(`${environment.apiUrl}/powerbi/create-workspaceid-for-imp`, {implementation_id: id})
      .pipe(map(x => x.Result));
  }

  getReportBreakingRules(impId) {
    return this.http
      .get<any>(`${environment.apiUrl}/powerbi/get-report-breaking-rules/${impId}`)
      .pipe(map(x => x.Result));
  }

  updateReportBreakingRules(reportImpList){
    return this.http
      .post<any>(`${environment.apiUrl}/powerbi/update-report-breaking-rules`, reportImpList)
      .pipe(map(x => x.Result));
  }

  getReportDashboardByImplementId(impId){
    return this.http.get<any>(`${environment.apiUrl}/powerbi/get-report-dashboard-by-impid/${impId}`).pipe(map(x => x.Result));
  }
}
